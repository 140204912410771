<template>
  <div class="pl-1">
    <h5 class="font-weight-bold my-2">CREW INCIDENT / BEHAVIOR</h5>
    <small>
      <strong class="font-weight-bolder">CER 1:</strong> 4 months after embarkation *
      <strong class="font-weight-bolder">CER 2:</strong> 1 month before disembarking *
      <strong class="font-weight-bolder">CER 3:</strong> Sign off Master/Chief Engineer *
      <strong class="font-weight-bolder">CER 4:</strong> Sign off Master/Chief Engineer
    </small>
    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center">Vessel</th>
        <th class="align-middle text-center">Rank</th>
        <th class="align-middle text-center">Start Date</th>
        <th class="align-middle text-center">End Date</th>
        <th class="align-middle text-center">CER 1</th>
        <th class="align-middle text-center">CER 2</th>
        <th class="align-middle text-center">CER 3</th>
        <th class="align-middle text-center">CER 4</th>
        <th class="align-middle text-center">CoEv</th>
        <th class="align-middle text-center" style="width: 10%;">Management Appraisal</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="crewDocuments">
        <tr v-for="cer in crewDocuments">
          <td>{{ cer.vesselName ? cer.vesselName.toUpperCase() : '' }}</td>
          <td class="text-center">{{ cer.rank ? cer.rank.name.toUpperCase() : '' }}</td>
          <td class="text-center">{{ dateEliteFormat(cer.start_date) }}</td>
          <td class="text-center">{{ dateEliteFormat(cer.end_date) }}</td>
          <td class="text-center">
            <button class="btn btn-xs"
                    data-target="#crewCer"
                    data-toggle="modal"
                    @click="setSelectedTab(1,cer)"
                    :class="{
                        'btn-secondary font-weight-bold': !cer.cer1,
                       'font-weight-bold': cer.cer1 && cer.cer1.grade
            }">
              {{ cer.cer1 ? cer.cer1.grade : 'N/A' }}
            </button>
          </td>
          <td class="text-center">
            <button class="btn btn-xs"
                    data-target="#crewCer"
                    data-toggle="modal"
                    @click="setSelectedTab(2,cer)"
                    :class="{
                        'btn-secondary font-weight-bold': !cer.cer2,
                       'font-weight-bold': cer.cer2 && cer.cer2.grade
            }">
            {{ cer.cer2 ? cer.cer2.grade : 'N/A' }}
            </button>
          </td>
          <td class="text-center">
              <button class="btn btn-xs"
                      data-target="#crewCer"
                      @click="setSelectedTab(3,cer)"
                      data-toggle="modal"
                      :class="{
                          'btn-secondary font-weight-bold': !cer.cer3,
                         'font-weight-bold': cer.cer3 && cer.cer3.grade
              }">
              {{ cer.cer3 ? cer.cer3.grade : 'N/A' }}
             </button>
          </td>
          <td class="text-center">
            <button class="btn btn-xs"
                    data-target="#crewCer"
                    @click="setSelectedTab(4,cer)"
                    data-toggle="modal"
                                           :class="{
                          'btn-secondary font-weight-bold': !cer.cer4,
                         'font-weight-bold': cer.cer4 && cer.cer4.grade
              }">
            {{ cer.cer4 ? cer.cer4.grade : 'N/A' }}
          </button>
          </td>
          <td class="text-center">
            <button class="btn btn-xs btn-primary" data-toggle="modal" data-target="#crewCoev" @click="getCompanyEvaluation(cer)">
              {{cer.evaluationCount}}
            </button>
          </td>
          <td class="text-center">
            <button class="btn btn-xs btn-primary"
                    data-toggle="modal"
                    data-target="#crewCer"
                    @click="setSelectedTab(5,cer)">
              {{cer.crew_comments.length}}
            </button>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewCer" tabindex="-1" aria-labelledby="crewCer" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h6 class="modal-title font-weight-bold" id="crewIncidentBehaviorTitle">{{crewInfo.fullName}} ({{ crewInfo.rank ? crewInfo.rank.toUpperCase() : '' }} - {{crewInfo.ipn ? crewInfo.ipn.toUpperCase() : ''}} - {{crewInfo.manning ? crewInfo.manning.toUpperCase() : ''}})</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <button class="nav-link" :class="{'active' : selected===1}" @click="setSelectedTab(1)" data-toggle="tab" role="tab"> CER 1</button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="{'active' : selected===2}"  @click="setSelectedTab(2)" data-toggle="tab"  role="tab" data-target="#cer2"> CER 2</button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="{'active' : selected===3}" @click="setSelectedTab(3)" data-toggle="tab"  role="tab" data-target="#cer3"> CER 3</button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="{'active' : selected===4}" @click="setSelectedTab(4)" data-toggle="tab" role="tab"  data-target="#cer4"> CER 4</button>
              </li>
              <li class="nav-item">
                <button class="nav-link"  data-toggle="tab" role="tab" data-target="#management-appraisal" @click="setSelectedTab(5)" :class="{'active' : selected===5}" > Management Appraisal</button>
              </li>
            </ul>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade" :class="{'show active' : selected===1}" id="cer1" role="tabpanel">
                <div class="row m-2">
                    <div class="col-4 py-2 card" style="min-height: 150px; height: 100%;">
                        <table class="w-100 ">
                          <tr>
                              <td>Grade:</td>
                              <td>{{cer.grade}}</td>
                          </tr>
                        </table>
                    </div>
                    <div class="col-8" style="height: 77vh">
                      <template v-if="cerFile">
                        <iframe style="height: inherit; width: 100%" :src="cerFile"></iframe>
                      </template>
                      <div v-else class="alert alert-danger">
                        NO FILE FOUND
                      </div>
                    </div>
                </div>
              </div>
              <div class="tab-pane fade" :class="{'show active' : selected===2}" id="cer2" role="tabpanel">
                <div class="row m-2">
                  <div class="col-4 py-2 card" style="min-height: 150px; height: 100%;">
                    <table class="w-100 ">
                      <tr>
                        <td>Grade:</td>
                        <td>{{cer.grade}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-8" style="height: 77vh">
                    <template v-if="cerFile">
                      <iframe style="height: inherit; width: 100%" :src="cerFile"></iframe>
                    </template>
                    <div v-else class="alert alert-danger">
                      NO FILE FOUND
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" :class="{'show active' : selected===3}" id="cer3" role="tabpanel">
                <div class="row m-2">
                  <div class="col-4 py-2 card" style="min-height: 150px; height: 100%;">
                    <table class="w-100 ">
                      <tr>
                        <td>Grade:</td>
                        <td>{{cer.grade}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-8" style="height: 77vh">
                    <template v-if="cerFile">
                      <iframe style="height: inherit; width: 100%" :src="cerFile"></iframe>
                    </template>
                    <div v-else class="alert alert-danger">
                      NO FILE FOUND
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" :class="{'show active' : selected===4}" id="cer4" role="tabpanel">
                <div class="row m-2">
                  <div class="col-4 py-2 card" style="min-height: 150px; height: 100%;">
                    <table class="w-100 ">
                      <tr>
                        <td>Grade:</td>
                        <td>{{cer.grade}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-8" style="height: 77vh">
                    <template v-if="cerFile">
                      <iframe style="height: inherit; width: 100%" :src="cerFile"></iframe>
                    </template>
                    <div v-else class="alert alert-danger">
                      NO FILE FOUND
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="management-appraisal" role="tabpanel" :class="{'show active' : selected===5}">
                <h6 class="py-2 mb-0 font-weight-bold">ALL COMMENTS</h6>
                <hr class="m-0">
                <template v-if="cer">
                    <div class="row mt-2">
                      <div class="col-12" v-for="comment in cer">
                        <div class="card p-2 mb-2">
                          <div class="card-title m-0 p-0"><p class="font-weight-bold">{{comment ? comment.period : 'N/A'}} ago</p></div>
                          <div class="card-body m-0 p-2">
                            <div class="row">
                              <div class="col-12">
                                <textarea class="w-100 p-2" rows="5" disabled>{{comment ? comment.comment : ''}}</textarea>
                              </div>
                              <div class="col-4">
                                <table class="w-100">
                                  <tr>
                                    <td style="width: 30%">Commented By:</td>
                                    <td class="font-weight-bold">{{!comment ? '' : comment.commentator ? comment.commentator.name : ''}}</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-12">
                                <p class="font-weight-bold m-0">Files:</p>
                                <template v-if="comment && comment.attachments">
                                  <a href="#" class="font-weight-bold mr-1" v-for="(attachment,index) in comment.attachments" @click="getCommentAttachment(comment.id,index)" >
                                    File#{{ index + 1}}.pdf
                                  </a>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewCoev" tabindex="-1" aria-labelledby="crewCoev" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" id="crewIncidentBehaviorTitle">Company Evaluation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-if="companyEvaluations.company_evaluations">

                <div class="col-12" v-for="(evaluation,index) in companyEvaluations.company_evaluations">
                  <div>
                    <p class="font-weight-bold">Company Evaluation # {{index + 1}}</p>
                  </div>
                  <textarea class="w-100 p-2" rows="5" disabled>{{evaluation ? evaluation.evaluation : ''}}</textarea>
                  <table class="w-25">
                    <tr>
                      <td>Attachment:</td>
                      <td>
                        <button class="btn-secondary btn-xs btn" :disabled="!evaluation.hasFile" @click="getCompanyEvaluationFile(evaluation.id)">View Attachment</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

//1 -> CER 1
//2 -> CER 2
//3 -> CER 3
//4 -> CER 4
//5 -> Management Appraisal

export default {
  name: "CrewCer",
  data(){
    return{
      selected:1,
      mainCer:{},
      cer:{},
      cerFile:'',
    }
  },
  async created() {
    const params = {
      cms_id: this.$route.params.id
    }
    await this.getCrewCers(params)
  },
  methods: {
    ...mapActions(['getCrewCers','getCompanyEvaluations']),
    dateEliteFormat(date) {
      return DateService.dateEliteFormat(date);
    },
    async setSelectedTab(num,cer=null){
      this.cerFile='';
      if(cer){
        this.mainCer=Object.assign({},cer)
      }
      this.selected=num
      const checker=[1,2,3,4].includes(this.selected) ? this.mainCer['cer'+num] : this.mainCer.crew_comments;
      this.cer=Array.isArray(checker) ? ( checker ?? []) : Object.assign({},checker ?? {grade:'',cer_no: num});
      if(!Array.isArray(this.cer) && this.cer.hasFile){
        const file=await CrewService.getCrewCerFile({cerEvaluation:this.cer.id})
        this.cerFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      }
    },
    async getCommentAttachment(commentId,index){
      this.cerFile='';
      const params = {
        crewComment:commentId,
        fileNum:index
      }
      const file=await CrewService.getCrewCommentAttachment(params)
      const commentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(commentFile){
        swal.fire({
          title: `<strong>File # ${index + 1}.pdf</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${commentFile}"></iframe>`
        })
      }

    },

    async getCompanyEvaluation(cer){
      const params ={
        crewActivity:cer?.id
      }
      await this.getCompanyEvaluations(params);
    },

    async getCompanyEvaluationFile(evaluationId){
      this.cerFile='';
      const params = {
        companyEvalId:evaluationId
      }
      const file=await CrewService.getCompanyEvaluationsFile(params)
      const commentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(commentFile){
        swal.fire({
          title: `<strong>Company Evaluation File</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${commentFile}"></iframe>`
        })
      }

    },
  },
  computed: {
    ...mapGetters(['crewDocuments','crewInfo','companyEvaluations'])
  }
}
</script>

<style scoped>
.nav.nav-tabs .nav-item {
  font-weight: bold;
}
.nav.nav-tabs .nav-item .nav-link{
  color:black !important;
  font-weight: bold;
}
</style>
